/* eslint-disable no-undef */

import React, { Component } from "react"
import { BrowserRouter, Route } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import loadable from "loadable-components"
import "./App.scss"

const theme = {
  text: "#2e2e2e",
  background: "#fff",
  primary: "hsl(215, 100%, 47%)",
  white: "#fff",
  secondary: "#c0c",
  success: "hsl(94, 48%, 56%)",
  warning: "hsl(46, 100%, 61%)",
  error: "hsl(8, 74%, 49%)",
  facebookThemeColor: "linear-gradient(45deg, #0668E1, #0080FB )",
  themeColorDark: "#173753",
  themeText: "#3366ca",
  themeColor: "#0063f0",
  palerBackground: "#f8f8f8",
  lightButtonIcon: "#b9b9b9",
  highlight: "#e0e",
  muted: "#f6f6ff",
  paleBackground: "#f0f2f7",
  superPalerBackground: "#f3f3f3",
  gray12: "#1d1d1d",
  gray11: "#272727",
  gray10: "#2e2e2e",
  gray9: "#3c3c3c",
  gray7: "#626262",
  gray6: "#707070",
  gray5: "#929292",
  gray4: "#a5a5a6",
  gray3: "#bbbbbc",
  gray2: "#cccccc",
  gray15: "#e6e6e6",
  gray1: "#e8e7e8",
  gray05: "#f0f0f0",
  gray02: "#f7f7f7",
  gray0: "#fafafa",
}

const Home = loadable(() => import("./pages/Home/Home"))
const Media = loadable(() => import("./pages/Media/Media"))

class App extends Component {


  render() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <div className="app">
            <Route path="/" exact render={(props) =>
              (
                <Home
                  {...props}
                />
              )}
            />
            <Route path="/media" exact render={(props) =>
            (
              <Media
                {...props}
              />
            )}
            />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App
