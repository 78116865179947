import React from 'react';
import {hydrate, render} from 'react-dom'
import { loadComponents } from "loadable-components"

import App from './App'

// window.snapSaveState = () => {
//   const state = getState()
//   console.log(JSON.stringify(state))
//   return state
// }
const rootElement = document.getElementById('root')
// const theme = require("sass-extract-loader?{\"plugins\": [\"sass-extract-js\"]}!./stylesheets/var.scss")

loadComponents()
  .then(() => hydrate(<App />, rootElement))
  .catch(() => render(<App />, rootElement));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
